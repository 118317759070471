<script lang="ts" setup>
defineProps<{
  components: {
    name: string
    deliveryId: string
    locale: string
    vse?: string
    content?: any
  }[]
  firstPageContent?: boolean
  width?: '25' | '33' | '50' | '100'
}>()
const comps = import.meta.glob('@integration-layer/components/Amplience/*.vue')
const entrs = Object.entries(comps)
const mappd = entrs.map(([k, v]) => [k.replace('../integration-layer', ''), v])
const AmplienceComponents = Object.fromEntries(mappd)
const componentsMap = new Map([
  [
    'https://armani.com/accordion-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceAccordionItem.vue']
    ),
  ],
  [
    'https://armani.com/accordion',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceAccordion.vue']
    ),
  ],
  [
    'https://armani.com/authenticity',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceAuthenticity.vue']
    ),
  ],
  [
    'https://armani.com/banner-editorial',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceBannerEditorial.vue']
    ),
  ],
  [
    'https://armani.com/book-appointment',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceBookAppointment.vue']
    ),
  ],
  [
    'https://armani.com/brand-homepage',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceBrandHomepage.vue']
    ),
  ],
  [
    'https://armani.com/brand-slot',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceBrandSlot.vue']
    ),
  ],
  [
    'https://armani.com/brand-socials',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceBrandSocials.vue']
    ),
  ],
  [
    'https://armani.com/cart-box-sustainable-packaging',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCartBoxSustainablePackaging.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cart-message-and-box',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCartMessageAndBox.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cart-message-condition',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCartMessageCondition.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cart-messages-collection',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCartMessagesCollection.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cart-text-collection',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCartTextCollection.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cart-text',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceCartText.vue']
    ),
  ],
  [
    'https://armani.com/checkout-typ-feedback',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCheckoutTypFeedback.vue'
      ]
    ),
  ],
  [
    'https://armani.com/cross-navigation',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceCrossNavigation.vue']
    ),
  ],
  [
    'https://armani.com/crossbrand-homepage-newest',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceCrossbrandHomepageNewest.vue'
      ]
    ),
  ],
  [
    'https://armani.com/crossbrand-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceCrossbrandItem.vue']
    ),
  ],
  [
    'https://armani.com/editorial-accordion',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialAccordion.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-carousel-chip-content',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialCarouselChipContent.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-carousel-product-with-chips',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialCarouselProductWithChips.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-discover-alternative-chip-content',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialDiscoverAlternativeChipContent.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-discover-alternative',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialDiscoverAlternative.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-discover-card',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialDiscoverCard.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-discover',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialDiscover.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-fashion-show',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialFashionShow.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-gallery-hover-card',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialGalleryHoverCard.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-gallery-hover',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialGalleryHover.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-grid-fashion-show-gallery',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialGridFashionShowGallery.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-irregular',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialIrregular.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-modal',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceEditorialModal.vue']
    ),
  ],
  [
    'https://armani.com/editorial-modal-video',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialModalVideo.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-page',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceEditorialPage.vue']
    ),
  ],
  [
    'https://armani.com/editorial-product-focus-card',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialProductFocusCard.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-product-focus',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialProductFocus.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-product-focus-table',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialProductFocusTable.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-product-focus-text',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialProductFocusText.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-product-hero',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialProductHero.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-sidesheet',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialSidesheet.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-slide-show-gallery-card-new',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialSlideShowGalleryCardNew.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-slide-show-gallery-new',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialSlideShowGalleryNew.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-social-strip',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialSocialStrip.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-social-wall',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialSocialWall.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-strip-anchors-content',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialStripAnchorsContent.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-strip-anchors',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialStripAnchors.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-text-module',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialTextModule.vue'
      ]
    ),
  ],
  [
    'https://armani.com/editorial-timeline',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceEditorialTimeline.vue'
      ]
    ),
  ],
  [
    'https://armani.com/footer-links',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceFooterLinks.vue']
    ),
  ],
  [
    'https://armani.com/grid-module-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceGridModuleItem.vue']
    ),
  ],
  [
    'https://armani.com/grid-module',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceGridModule.vue']
    ),
  ],
  [
    'https://armani.com/hero-banner',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceHeroBanner.vue']
    ),
  ],
  [
    'https://armani.com/icons-with-links-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceIconsWithLinksItem.vue'
      ]
    ),
  ],
  [
    'https://armani.com/icons-with-links',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceIconsWithLinks.vue']
    ),
  ],
  [
    'https://armani.com/image-schema',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceImageSchema.vue']
    ),
  ],
  [
    'https://armani.com/image-schema-without-ratio',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceImageSchemaWithoutRatio.vue'
      ]
    ),
  ],
  [
    'https://armani.com/image-with-logo-and-link-collection',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceImageWithLogoAndLinkCollection.vue'
      ]
    ),
  ],
  [
    'https://armani.com/image-with-logo-and-link',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceImageWithLogoAndLink.vue'
      ]
    ),
  ],
  [
    'https://armani.com/image-with-text',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceImageWithText.vue']
    ),
  ],
  [
    'https://armani.com/infos-with-title-and-cta-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceInfosWithTitleAndCtaItem.vue'
      ]
    ),
  ],
  [
    'https://armani.com/infos-with-title-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceInfosWithTitleItem.vue'
      ]
    ),
  ],
  [
    'https://armani.com/infos-with-title',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceInfosWithTitle.vue']
    ),
  ],
  [
    'https://armani.com/launching-categories',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceLaunchingCategories.vue'
      ]
    ),
  ],
  [
    'https://armani.com/legal-area-page-structure',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceLegalAreaPageStructure.vue'
      ]
    ),
  ],
  [
    'https://armani.com/livestory',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceLivestory.vue']
    ),
  ],
  [
    'https://armani.com/logic-wrapper-collection-plp',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceLogicWrapperCollectionPlp.vue'
      ]
    ),
  ],
  [
    'https://armani.com/logic-wrapper-collection',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceLogicWrapperCollection.vue'
      ]
    ),
  ],
  [
    'https://armani.com/logic-wrapper',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceLogicWrapper.vue']
    ),
  ],
  [
    'https://armani.com/marketing-strip',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceMarketingStrip.vue']
    ),
  ],
  [
    'https://armani.com/payment-methods',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmpliencePaymentMethods.vue']
    ),
  ],
  [
    'https://armani.com/plp-page',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmpliencePlpPage.vue']
    ),
  ],
  [
    'https://armani.com/plp-shop-the-look',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmpliencePlpShopTheLook.vue']
    ),
  ],
  [
    'https://armani.com/product-focus-item',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceProductFocusItem.vue']
    ),
  ],
  [
    'https://armani.com/product-tiles',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceProductTiles.vue']
    ),
  ],
  [
    'https://armani.com/service-strip',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceServiceStrip.vue']
    ),
  ],
  [
    'https://armani.com/shop-the-look-new',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceShopTheLookNew.vue']
    ),
  ],
  [
    'https://armani.com/spacing-schema',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceSpacingSchema.vue']
    ),
  ],
  [
    'https://armani.com/strip-layout',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceStripLayout.vue']
    ),
  ],
  [
    'https://armani.com/values-editorial',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceValuesEditorial.vue']
    ),
  ],
  [
    'https://armani.com/video-schema',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents['/components/Amplience/AmplienceVideoSchema.vue']
    ),
  ],
  [
    'https://armani.com/video-schema-without-ratio',
    // @ts-ignore
    defineAsyncComponent(
      AmplienceComponents[
        '/components/Amplience/AmplienceVideoSchemaWithoutRatio.vue'
      ]
    ),
  ],
])
</script>
<template>
  <div class="contents">
    <component
      :is="componentsMap.get(component.name)!"
      v-for="(component, i) in components"
      :key="component.deliveryId + i"
      :delivery-id="component.deliveryId"
      :locale="component.locale"
      :vse="component.vse"
      :content="component.content"
      :first-page-content="firstPageContent && i === 0"
      :full-width="width === '100'"
    />
  </div>
</template>
